export const convertSnakeToStartCase = (text) =>
  text
    ?.toLowerCase()
    .split('_')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')

export const convertCamelToStartCase = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => ` ${word.toUpperCase()}`)
    .replace(/\s+/g, ' ')

export const MAX_FILE_SIZE = 20000000

export const SUPPORTED_FORMATS = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
]

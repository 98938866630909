import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, Col, Container, Row } from 'react-bootstrap'
import FullLogo from '../assets/logos/full-logo.png'
import ProfileAvatar from '../components/profileAvatar'
import theme from '../constants/theme'
import BaseButton from '../components/buttons/BaseButton'
import {
  ExternalLink,
  Footer,
  FooterContent,
  GradientBackground,
  JobApplicationPositionCard,
  Typography,
} from '../components/styled'
import { ReactComponent as RecordFill } from '../assets/svgs/recordFill.svg'
import RichTextEditor from '../components/forms/RichTextEditor'
import { seniorityLevel, employmentType } from '../constants/candidate'
import LanguagesDropdown from '../components/languageDropdwn'

function JobApplication({ onApplyClick, company, position }) {
  const { t, i18n } = useTranslation()
  const locale = i18n?.language
  const handleClick = () => {
    onApplyClick()
  }

  const positionTextLabels = [
    { value: 'yearsOfExperience', label: `${t('pages.yearsOfExperience')}` },
    { value: 'language', label: t('pages.language') },
    { value: 'educationLevel', label: t('pages.educationLevel') },
    { value: 'travelFreq', label: t('pages.travelFreq') },
    { value: 'country', label: t('pages.location') },
  ]

  const positionArrayLabels = [
    { value: 'certificates', label: t('pages.certificates') },
    { value: 'knowledge', label: t('pages.knowledge') },
    { value: 'licenses', label: t('pages.licenses') },
    { value: 'skills', label: t('pages.skills') },
  ]

  const getEmploymentTypeLabel = (value) => {
    const label = employmentType.find((type) => type.value === value)
    return label ? label[locale] : ''
  }

  const getSeniorityLevelLabel = (value) => {
    const label = seniorityLevel.find((level) => level.value === value)
    return label ? label[locale] : ''
  }

  return (
    <GradientBackground
      className="vw-100 vh-100 overflow-auto d-flex justify-content-center position-relative"
      background={theme.gradientBackground}
    >
      <Container className="mt-1 fw-light">
        <Row className="my-2 align-items-end">
          <Col sm="auto">
            <img height="auto" width="130px" src={FullLogo} alt="" />
          </Col>
          <Col />
          <Col sm="1">
            <LanguagesDropdown />
          </Col>
        </Row>
        <Row>
          <JobApplicationPositionCard className="shadow bg-body rounded h-100 px-3 mb-5">
            <Row className="ms-0 m-3 mb-2 align-items-center fs-5">
              <Col>
                <Typography bold size="16.2px">
                  {t('pages.companyHiringPosition', {
                    company: company?.companyName,
                    position: position?.title,
                  })}
                </Typography>
              </Col>
              <Col className="d-flex" sm="auto">
                <BaseButton
                  variant="outline-light"
                  backgroundcolor={theme.brandColorOrange}
                  hoverbackgroundcolor={theme.brandColorLightOrange}
                  onClick={handleClick}
                >
                  {position?.isInternalApply
                    ? `${t('pages.applyWithCompanyID')}`
                    : `${t('pages.apply')}`}
                </BaseButton>
              </Col>
            </Row>
            <Row id="position-info" className="mb-3">
              <Col
                id="position-job-info"
                className=" px-2 py-0 mx-3 my-0 lh-sm"
                sm={5}
              >
                <Row id="job-title">
                  <Card id="job-title-card" className="px-4 py-3">
                    <Row
                      id="job-title-info"
                      className="py-2 align-items-center"
                    >
                      <Col id="job-title">
                        <Typography bold size="20px">
                          {position.title}
                        </Typography>
                      </Col>
                      <Col id="job-title-types" sm="auto">
                        <div className="d-flex justify-content-start">
                          <Card className="px-3 py-1 mx-1 rounded-pill border-1 border-primary">
                            <Typography size="13px">
                              {getEmploymentTypeLabel(position?.employmentType)}
                            </Typography>
                          </Card>
                          <Card className="px-3 py-1 mx-1 rounded-pill border-1 border-primary">
                            <Typography size="13px">
                              {getSeniorityLevelLabel(position?.seniorityLevel)}
                            </Typography>
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Row>
                <Row id="job-title-info" className="py-2">
                  <Typography
                    className="d-flex justify-content-start"
                    size="14px"
                  >
                    <div className="px-2">{company?.companyName}</div>
                    <div className="px-2">
                      <RecordFill className="mr-2" />{' '}
                      <span className="text-primary">
                        {position?.workPlaceType}
                      </span>
                    </div>
                  </Typography>
                </Row>
                <Row>
                  <Card className="px-4 py-3 overflow-hidden">
                    <Typography color={theme.brandColorOrange} size="15px">
                      {t('pages.jobDescription')}
                    </Typography>
                    <Typography className="mt-2 mb-3" bold size="14px">
                      {t('pages.responsibilities')}:
                    </Typography>
                    <Typography
                      className="fw-light lh-sm mt-2 mb-3"
                      size="13px"
                      regular
                    >
                      <RichTextEditor
                        value={position?.description}
                        onChange={() => {}}
                        toolbarHidden
                        readOnly
                        wrapperStyle={{}}
                        editorStyle={{}}
                      />
                    </Typography>
                  </Card>
                </Row>
              </Col>
              <Col
                id="company-details"
                className=" px-2 py-0 mx-3 my-0 lh-sm"
                sm={6}
              >
                <Row>
                  <Card className="px-4 py-3 overflow-hidden">
                    <Typography className="mb-2" bold size="14px">
                      {t('pages.jobRequirements')}
                    </Typography>
                    <Typography
                      size="13px"
                      id="company-details"
                      className="lh-sm"
                      regular
                    >
                      <div className="">
                        {positionTextLabels?.map((key) => (
                          <div key={key.value}>
                            <Typography>{key.label}: </Typography>
                            <Typography regular>
                              {position[key.value]}
                            </Typography>
                          </div>
                        ))}
                        {positionArrayLabels?.map((arrayKey) =>
                          position[arrayKey.value] &&
                          position[arrayKey.value].length ? (
                            <div key={arrayKey.value}>
                              <Typography>{arrayKey.label}:</Typography>
                              {position[arrayKey.value].map((key, index) => (
                                <Typography className="mr-2" regular>
                                  {key}
                                  {index <
                                    position[arrayKey.value].length - 1 && ', '}
                                </Typography>
                              ))}
                            </div>
                          ) : null,
                        )}
                      </div>
                      <div className="mt-2">
                        <Typography>{t('pages.requirements')}</Typography>
                        <br />
                        <Typography
                          className="fw-light lh-sm"
                          size="13px"
                          regular
                        >
                          <RichTextEditor
                            value={position?.requirements}
                            onChange={() => {}}
                            toolbarHidden
                            readOnly
                            wrapperStyle={{}}
                            editorStyle={{}}
                          />
                        </Typography>
                      </div>
                    </Typography>
                  </Card>
                </Row>
                <Row className="mt-3">
                  <Card className="px-4 py-3">
                    <Row className="d-flex align-items-center">
                      <Col>
                        <Typography
                          className="fw-normal"
                          color={theme.brandColorOrange}
                          size="15px"
                        >
                          {company.companyName}
                        </Typography>
                        <Typography size="13px" className="lh-sm" regular>
                          <div className="mt-2">
                            {t('general.location')}:
                            {company.workingLocations.map((location, index) => (
                              <span className="mx-1" key={location}>
                                {location}
                                {index < company.workingLocations.length - 1 &&
                                  ', '}
                              </span>
                            ))}
                          </div>
                          <div className="d-fex">
                            {t('general.website')}:{' '}
                            <ExternalLink
                              href={company.website}
                              target="_blank"
                            >
                              {company.website}
                            </ExternalLink>
                          </div>
                          <div>
                            {t('pages.field')}: {company.industry}
                          </div>
                        </Typography>
                      </Col>
                      <Col sm="auto" className="mb-auto">
                        <ProfileAvatar name={company.companyName} />
                      </Col>
                    </Row>
                  </Card>
                </Row>
              </Col>
            </Row>
          </JobApplicationPositionCard>
        </Row>
      </Container>

      <Footer className="fixed-bottom">
        {t('footer.TalentinoAI')}{' '}
        <FooterContent>© {new Date().getFullYear()}</FooterContent>{' '}
        {t('footer.allRightsReserved')}
      </Footer>
    </GradientBackground>
  )
}
JobApplication.propTypes = {
  onApplyClick: PropTypes.func.isRequired,
  company: PropTypes.shape({
    companyName: PropTypes.string,
    website: PropTypes.string,
    workingLocations: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    industry: PropTypes.string,
  }).isRequired,
  position: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    requirements: PropTypes.string,
    employmentType: PropTypes.string,
    isInternalApply: PropTypes.bool,
    seniorityLevel: PropTypes.string,
    workPlaceType: PropTypes.string,
  }).isRequired,
}

export default JobApplication

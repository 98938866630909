import React, { Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Calendar from './pages/Calendar'
import Application from './pages/application'
import NotificationMessagePage from './components/notificationMessagePage'
import InterviewStageController from './pages/interviewStageController'

function App() {
  const { t } = useTranslation()
  return (
    <Suspense fallback={t('pages.loading')}>
      <Routes>
        <Route path="/calendar/:token" element={<Calendar />} />
        <Route path="/job-application/:token" element={<Application />} />
        <Route
          path="/interview/:interviewToken"
          element={<InterviewStageController />}
        />
        <Route
          path="*"
          element={
            <NotificationMessagePage
              messageType="error"
              message={t('pages.pageNotFound')}
            />
          }
        />
      </Routes>
    </Suspense>
  )
}
export default App

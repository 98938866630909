import apiProvider from './utilities/provider'

const url = 'company-positions'

const apiCompanyPositions = {
  getSingleWithToken: (token) =>
    apiProvider.getSingle(`${url}/application`, token),
}

export default apiCompanyPositions

// mapped to enums in api/models/enum_fields.py
export const getFormattedDate = (date) => {
  const year = date.getFullYear()
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${year}-${month}-${day}`
}

export const currency = [
  { value: 'USD', label: 'USD' },
  { value: 'CAD', label: 'CAD' },
  { value: 'GBP', label: 'GBP' },
  { value: 'EUR', label: 'EUR' },
  { value: 'SAR', label: 'SAR' },
  { value: 'QAR', label: 'QAR' },
  { value: 'AED', label: 'AED' },
  { value: 'EGP', label: 'EGP' },
]
